<style>
.ivu-table .table-invalid-row td {
  color: #777 !important;
}
.ivu-table .ivu-table-row-hover td {
  color: #aaa !important;
}
</style>

<template>
  <div>
    <Row  :gutter="8">
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="开票日期"
          v-model="createDate"
          size='small'
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <!-- <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="收付日期"
          v-model="paymentDate"
          size='small'
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col> -->
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select
          style="width: 100%"
          v-model="query.type"
          size='small'
          clearable
          placeholder="发票类型"
        >
          <Option value="0">专票</Option>
          <Option value="1">普票</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <i-input
          placeholder="关键字"
          v-model="query.keyword"
          size='small'
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="6" :lg="4" class="m-b-5"  style="min-width:150px;">
        <i-button
          class="m-r-5"
          type="primary"
          icon="ios-search"
          size='small'
          @click="handleSearch()"
          >搜索</i-button
        >
       <i-button
          type="success"
          size='small'
          v-if="isAuth('payment_download')"
          @click="handleExportExcel"
          >导出Excel</i-button
        >
      </i-col>
    </Row>

    <Table
      stripe
      :data="contractList"
      :columns="tableColumns"
      :row-class-name="rowClassName"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import {
  getInvoicePage,
  getinvoicesummaryv2
  // invaliddebtgroupcashflow
} from '@/api/scp/invoice'
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    params: Object
  },
  data () {
    return {
      query: {
        type: '',
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      total: 0,
      createDate: '',
      paymentDate: '',
      taskTypes: [],
      contractList: [],
      tableColumns: [
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '合同名称',
          key: 'contractName'
        },
        {
          title: '发票号码',
          key: 'code'
        },
        {
          title: '开票金额(含税)',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  style: { color: '#44bd32' }
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        { title: '开票日期', key: 'date' },
        {
          title: '更新时间',
          key: 'applyUserName',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.status === -1
                  ? params.row.invalidTime
                  : params.row.createTime
              )
            ])
          }
        },
        {
          title: '发票类型',
          key: 'typeName'
        },
        {
          title: '费用类型',
          key: 'feeTypeName'
        },
        // {
        //   title: '开票人',
        //   key: 'createUserName'
        // },
        {
          title: '状态',
          key: 'statusName',
          render: (h, params) => {
            const color = this.formaStautsColor(params.row.status)
            return h('div', [
              h(
                'Tag',
                {
                  props: {
                    color: color
                  }
                },
                params.row.statusName
              )
            ])
          }
        },
        {
          title: '备注',
          key: 'statusName',
          render: (h, params) => {
            return h('div', [
              params.row.status === -1
                ? h(
                  'span',
                  params.row.invalidMark ? params.row.invalidMark : '-'
                )
                : h('span', params.row.reason ? params.row.reason : '-')
            ])
          }
        }
        // {
        //   title: '操作',
        //   key: 'action',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('div', [
        //       params.row.status === 1
        //         ? h(
        //           'a',
        //           {
        //             style: { marginRight: '5px', color: '#ef4f4f' },
        //             on: {
        //               click: () => {
        //                 this.invalid(params.row.id)
        //               }
        //             }
        //           },
        //           '取消'
        //         )
        //         : '-'
        //     ])
        //   }
        // }
      ]
    }
  },
  created () {
    this.initPageData()
  },

  methods: {
    formaStautsColor (status) {
      let color = ''
      if (status === -1) {
        // 无效
        color = '#3B3B3B'
      } else if (status === 1) {
        // 有效
        color = '#44bd32'
      }

      return color
    },
    rowClassName (row, index) {
      if (row.status === -1) {
        return 'table-invalid-row'
      }
      return ''
    },
    formatMoney (number) {
      return toMoney(number)
    },
    initGroupSummary () {
      getinvoicesummaryv2(this.query).then((res) => {
        this.$store.commit('set_payment_contractSummary', res)
        this.$store.commit('set_payment_summaryType', '3')
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.query.startDate = this.formatDate(this.createDate[0])
      this.query.endDate = this.formatDate(this.createDate[1])
      this.initPageData()
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    handleInvalid (item) {
      let desc = ''
      this.$Modal.confirm({
        title: '作废收付信息？',
        render: (h) => {
          return h('Input', {
            attrs: {
              placeholder: '请输入作废原因'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': (event) => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          // invaliddebtgroupcashflow({
          //   cashflowId: item.id,
          //   invalidMark: desc
          // }).then((res) => {
          //   if (res && !res.errcode) {
          //     this.$Notice.success({ desc: '作废成功' })
          //     this.initPageData()
          //   }
          // })
        }
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    initPageData () {
      this.initGroupSummary()
      getInvoicePage(this.query).then((res) => {
        this.contractList = res.list
        this.total = res.totalRow
      })
    },
    handleExportExcel () {
      const query = Object.assign({}, this.query)
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-scp/v1/invoice/exportinvoices',
        query,
        '发票明细列表.xlsx'
      )
    }
  }
}
</script>
